<template>
  <ValidationProvider
    :rules="validationRules"
    class="question column"
    :class="width"
    tag="div"
    v-slot="{ errors, valid }"
    v-if="!reviewMode"
  >
    <div class="question-text has-text-weight-bold">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content">
      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
        <b-input
          :controls="false"
          :disabled="loading"
          :use-html5-validation="false"
          :value="value[`${question.qid}`]"
          @input="$emit('input', { [`${question.qid}`]: $event })"
          v-numeric-only
          expanded
          step="any"
          type="number"
          :placeholder="$t('multiple_input.number_placeholder')"
        ></b-input>
        <p class="control is-borderless" v-if="qOpts.suffix">
          <span v-if="qOpts.suffix.config" class="button is-static">{{ qOpts.suffix.config[lang] || qOpts.suffix.is_enabled }}</span>
          <span v-else class="button is-static">{{ qOpts.suffix }}</span>
        </p>
      </b-field>
    </div>
  </ValidationProvider>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">
          {{ question.code }}
        </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div class="question-content">
        <b-field>
          <b-input
            :controls="false"
            :readonly="loading"
            :use-html5-validation="false"
            :value="value[`${question.qid}`]"
            v-numeric-only
            expanded
            step="any"
            type="number"
            :placeholder="$t('multiple_input.number_placeholder')"
          ></b-input>
          <p class="control is-borderless" v-if="qOpts.suffix">
            <span v-if="qOpts.suffix.config" class="button is-static">{{ qOpts.suffix.config[lang] || qOpts.suffix.is_enabled }}</span>
            <span v-else class="button is-static">{{ qOpts.suffix }}</span>
          </p>
        </b-field>
    </div>
  </div>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { NumericOnlyDirective } from "@/directives/numeric-only.directive";
export default {
  name: "NumberInput",
  mixins: [QuestionMixin],
  directives: {
    "numeric-only": NumericOnlyDirective,
  },
};
</script>
